import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SumSub from './components/sumSub';

const Router: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={`/:type`}
        element={<SumSub />}
      />
    </Routes>
  );
};

export default Router;
