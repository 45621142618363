const BASE_URL = process.env.REACT_APP_SUMSUB_SERVICE;

const getXSessionID = () => {
  try {
    const response = new URLSearchParams(window.location.search)
    const xSessionID = response.get('x-session-id');
    return xSessionID;
  } catch (err) {
    console.log(err);
  }
};

const getSessionData = async () => {
  try {
    const xSessionID = (await getXSessionID()) || '';
    const response = await fetch(`${BASE_URL}/api/session/get`, {
      method: 'GET',
      headers: {
        'x-session-id': xSessionID,
      },
    });
    const sessionData = await response.json();
    return sessionData;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getToken = async (accountType: string) => {
  try {
    const xSessionID = (await getXSessionID()) || '';
    const response = await fetch(`${BASE_URL}/api/applicant/token`, {
      method: 'POST',
      headers: {
        'x-session-id': xSessionID,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type: accountType }),
    });

    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { getSessionData, getToken };