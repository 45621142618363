import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loader from '../../components/loader';
import { DarkStyles } from './styles';
import { getSessionData, getToken } from './api';

const applicantSubmitStatuses: string[] = ['idCheck.onApplicantSubmitted', 'idCheck.onApplicantResubmitted'];
const formWidgetId: string = 'sumsub-websdk-container';

declare global {
  interface Window {
    snsWebSdk: any;
  }
};

const SumSub: React.FC = (): JSX.Element => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [darkStyles, setDarkStyles] = useState<string>('');
  const [searchParams]: any = useSearchParams();
  const lang: string = searchParams.get('lang');
  const location = useLocation();
  const urlParts = location.pathname.split('/');
  const accountType = urlParts[urlParts.length - 1] || 'individual';

  useEffect(() => {
    Promise.all([getSessionData(), getToken(accountType)])
      .then(([sessionData, token]) => {
        launchWebSdk(token);
        setShowLoader(false);
        setDarkStyles(DarkStyles()[sessionData.exchange]);
      })
      .catch((error) => {
        console.log('Error while fetching session data:', error)
      });
  }, []);

  function launchWebSdk(token: string, customI18nMessages?: any) {
    const snsWebSdkInstance = window.snsWebSdk
      .init(token, (newAccessTokenCallback: Function) => {
        newAccessTokenCallback && newAccessTokenCallback(token);
      })
      // .onTestEnv() // remove on production
      .withConf({
        lang: lang || 'en',
        i18n: customI18nMessages,
        uiConf: {
          customCssStr: darkStyles,
        },
        onMessage: (type: string) => {
          // see below what kind of messages the WebSDK generates
          if (applicantSubmitStatuses.includes(type)) {
            window.parent.postMessage({ message: 'run getKyc' }, '*');
          }
        },
        onError: (error: string) => {
          console.error('SumSum WebSDK onError', error);
        },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .build();

    snsWebSdkInstance.launch(`#${formWidgetId}`);
  };

  return (
    <div className='corporate-form-widget-container'>
      <div id={formWidgetId} />
      {showLoader && <Loader />}
    </div>
  );
};

export default SumSub;