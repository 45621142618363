export const TestDemoStyles: string = `
  button.submit, button[type=submit], button.back {
    background: #7acd45;
    height: 36px;
    max-width: 260px;
    width: 100%;
    color: #000
  }
  button:hover {
    background: #67b832; background-image: unset !important;
  }
  a {
    color: #7acd45;
  }
  .steps {
    .title {
      color: #c6c6cd; //???
    }
  }
  .step .title, .step.active .title {
    color: #838097;
    padding-top: 45px
  }
`;
