import React from 'react';

import './style.scss';

const Loader: React.FC = (): JSX.Element => {
  return (
    <div className='nova-loader-container'>
      <i className='loader-layer' />
      <i className='loader-layer' />
      <i className='loader-layer' />
    </div>
  );
};

export default Loader;
