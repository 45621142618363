import { mainConfig } from '../configs';

export const addScriptSumSub = () => {
  if (!document.getElementById('sumsub-initialize')) {
    const scriptInit = document.createElement('script');

    scriptInit.id = 'sumsub-initialize';
    scriptInit.src = mainConfig.sumsub.initialize;

    document.getElementsByTagName('body')[0].appendChild(scriptInit);
  }
};
