export enum ClientNames {
  Test = 'TESTDEMO',
};

export interface DecodedToken {
  exchange: string;
  exp: string;
  iat: string;
  iss: string;
  role: string;
  sub: string;
};
